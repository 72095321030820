<template>
    <div :style="'height:' + innerHeight + 'px;vertical-align: bottom;'">
        <div class="bg"></div>
        <div class="e">
            <div style="width:500px;background-color: rgba(255, 255, 255, 0.5);min-height: 400px;" class="flex_cen">          
                <div style="width:500px;border: 1px solid #eeeeee;background: #fff; min-height: 400px;"  >
                    <div style="margin:20px 30px">
                        <!-- <h2 class="text_cen heard_tit">欢迎登录</h2> -->
                        <div style="margin:48px 0;width: 100%;display: flex;justify-content: center;align-items: center;padding-bottom: 24px;">
                            <img style="width:35%" src="@/assets/image/huanying.png"  />
                        </div>                        
                        
                        <Form ref="formInline" :model="formInline" style="width:80%;margin-left: 10%;" :rules="ruleInline">
                            <FormItem prop="account" >
                                <Input type="text"  v-model="formInline.account" placeholder="请输入账户">
                                <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="pwd">
                                <Input type="password" v-model="formInline.pwd" placeholder="请输入密码">
                                <Icon size="18" type="ios-lock-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>                           
                        </Form>
                        <!-- <div class=" flex_end opa_qi shubiao" @click="forgetPWD()"  style="margin-right:10%">忘记密码?</div> -->
                        <div class="login_button" @click="handleSubmit">登录</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { login } from "@/api/index";
import { setCookies } from '@/utils/helper'
export default {
    metaInfo: {
        title:'登陆',
        meta: [
            { name: 'viewport', content: 'width=device-width,user-scalable=yes,initial-scale=0.3,minimum-scale=0.3,maximum-scale=2' }
        ]
    },
    data() {
        return {
            innerHeight: 0,
            formInline: {
                account: '',
                pwd: ''
            },
            ruleInline: {
                account: [
                    { required: true, message: '账户不能为空', trigger: 'blur' }
                ],
                pwd: [
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    created() {
        var _this = this
        document.onkeydown = function () {
            if (_this.$route.name === '登录') {
                let key = window.event.keyCode
                if (key === 13) {
                    _this.handleSubmit()
                }
            }
        }
    },
    // 移除监听事件
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        /**修改高度 */       
        handleResize() {
            this.innerHeight = window.innerHeight - 2 
        },
        forgetPWD(){
            return
            this.$Message.error('请联系管理员修改密码')
        },
        handleSubmit() {  
            // localStorage.setItem("menuType",'newhomepage') 
            // this.$router.push({ path: '/admin/newhomepage' })   
            // return 
            //主题色#4877E8
            // this.formInline.account='admin'
            // this.formInline.pwd = '123456'
            if(this.formInline.account == ''){
                this.$Message.error('请输入账户')
                return
            }    
            if(this.formInline.pwd == ''){
                this.$Message.error('请输入密码')
                return
            }
            login(this.formInline).then(res => {                         
                localStorage.setItem("menuType",'newhomepage') 
                setCookies('teeth-token', res.data.token)
                // this.$store.commit('setUserInfo', res.data.user_info)
                this.$Message.success(res.msg || '登陆成功');                
                this.$router.push({ path: '/admin/newhomepage' })              
            }).catch(res => {
                this.$Message.error(res.msg);
            })
        },
       
    }
}
</script>
<style scoped>
.ivu-icon {
    width: 30px
}
.login_button {
    width: 80%;
    height: 38px;
    line-height: 38px;
    background: #4877E8;
    color: #fff;
    margin-left: 10% ;
    cursor: pointer;
    text-align: center;
    margin-top: 38px;
    border-radius: 5px;
}
.login_button:hover {
    opacity: 0.7;
}
.flex_end {
    /* display: flex; */
    display: flex;
    justify-content: flex-end;
}

.e {
    margin-top: -20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
}
.bg {
    background: url("../../assets/14.jpg") no-repeat;
    /* background: #2f3447; */
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
</style>
